
export default {
	name: 'HeaderSign',
	props: ['titleName'],
	methods: {
		backIndex() {
			this.$router.push({ path: '/' })
		}
	}
}
